/* eslint-disable no-useless-escape */
import React, { ReactElement, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import {
  Button, Panel, Table, Tag
} from 'rsuite'

import {
  useApi, useModal, usePageTitle, useUser
} from '../../app/hooks'
import { MissingPaymentMethodModal } from '../account/MissingPaymentMethodModal'
import { RowData } from '../../types/table'
import { TableData, ActionMenu, Modal } from '../../components'
import { isSubscriptionStatus } from '../../types/subscriptionHelpers'
import { getSubscriptionStatusTagColor, isSubscriptionActive } from './helpers'
import {
  getPaymentMethod,
  cancelSubscription,
  getSubscriptionEndDate,
  getSubscriptions,
  requestSubscriptionModification
} from '../../services/graphql/queries'
import { SubscriptionCancellationModal } from './SubscriptionCancellationModal'
import { SubscriptionDTO } from '../../types/subscription'
import { replaceInPaginatedWithId } from '../../helpers'

import './CustomerSubscriptionsList.css'
import { SubscriptionModifyModal } from './SubscriptionModifyModal'
import { SubscriptionStatus } from '../../types/enums'

/**
 * Lists a customer's subscriptions
 * @return {ReactElement}
 */
function CustomerSubscriptionList (): ReactElement {
  usePageTitle('Subscriptions')

  const createModal = useModal()
  const cancelModal = useModal()
  const modifyModal = useModal()
  const [selectedSubscription, setSelectedSubscription] = useState<SubscriptionDTO>()

  const user = useUser()
  const modificationQuery = useApi(requestSubscriptionModification)
  const subscriptionQuery = useApi(getSubscriptions, {
    initialFetch: true, initialFetchParams: { userId: user.id },
  })
  const cancelSubscriptionQuery = useApi(cancelSubscription)
  const getEndDateQuery = useApi(getSubscriptionEndDate)

  const navigate = useNavigate()

  const userPaymentFetch = useApi(getPaymentMethod, {
    displayErrorAlerts: false,
    cleanUpOnDismount: true,
    initialFetch: true,
    initialFetchParams: { userId: user.id },
  })

  const userPaymentRes = userPaymentFetch.getResponse()

  /**
  * Cb handles when user clicks the new subscription button
  */
  const handleNewSubscriptionCb = () => {
    if (!userPaymentRes.loading && userPaymentRes.data !== null) {
      navigate('/subscriptions/new')
    } else {
      createModal.show()
    }
  }

  /**
   * Cancel a subscription
   * @param {RowData} id - The ID of the subscription to cancel
   */
  const cancel = (subscription: RowData): void => {
    getEndDateQuery.sendRequest({ subscriptionId: subscription.id })
    setSelectedSubscription(subscription as SubscriptionDTO)
    cancelModal.show()
  }

  /**
   * Modify a subscription
   * @param {RowData} id - The ID of the subscription to modify
   */
  // const modify = (subscription: RowData): void => {
  //   setSelectedSubscription(subscription as SubscriptionDTO)
  //   modifyModal.show()
  // }

  const sendModification = (vehicles: number): void => {
    if (selectedSubscription) {
      modificationQuery.sendRequest(
        {
          id: selectedSubscription.id,
          modificationRequest: JSON.stringify({
            vehicles,
          }),
        },
        (result: any) => {
          modifyModal.hide()
          subscriptionQuery.setData((oldData) => replaceInPaginatedWithId(oldData, result))
          return result
        }
      )
    }
  }

  const sendCancellation = (): void => {
    if (selectedSubscription) {
      cancelSubscriptionQuery.sendRequest(
        { subscriptionId: selectedSubscription?.id },
        (result: any) => {
          cancelModal.hide()
          subscriptionQuery.setData((oldData) => replaceInPaginatedWithId(oldData, result))
          return result
        }
      )
    }
  }

  /**
   * Table.Cell child for rendering a subscriptions carpark name
   * @param {Subscription} subscription
   * @return {ReactElement}
   */
  const renderCarpark = (subscription: RowData): React.ReactElement => (
    subscription.location?.name
  )

  /**
   * Table.Cell child for rendering a subscription's vehicle count
   * @param {Subscription} subscription
   * @return {ReactElement}
   */
  const renderVehicles = (subscription: RowData): React.ReactElement => (
    subscription.vehicles
  )

  /**
   * Table.Cell child for rendering a subscription's status
   * @param {Subscription} subscription
   * @return {ReactElement}
   */
  const renderStatus = (subscription: RowData): React.ReactElement => {
    const text = subscription.status === SubscriptionStatus.AMEND
      ? 'AMENDMENT PENDING' : subscription.status
    if (isSubscriptionStatus(subscription?.status)) {
      const color = getSubscriptionStatusTagColor(subscription.status)
      return (
        <Tag color={color}>
          {text}
        </Tag>
      )
    }
    return (
      <Tag>
        {text}
      </Tag>
    )
  }

  /**
   * Table.Cell for rendering a subscriptions's total cost
   * @param {Subscription} subscription
   * @return {ReactElement}
   */
  const calculateTotalPrice = (subscription: RowData): string => (
    (Number(subscription.plan?.price) * Number(subscription.vehicles)).toString()
  )

  /**
   * Dropdown menu for a row in the subscriptions list table
   * @param {RowData} subscription
   * @return {ReactElement}
   */
  const renderActions = (subscription: RowData): ReactElement => {
    const actions = []
    if (isSubscriptionActive(subscription.status)) {
      actions.push({ label: 'Cancel subscription', action: () => cancel(subscription) })
      // actions.push({ label: 'Amend subscription', action: () => modify(subscription) })
    }
    if (subscription.startDate) {
      actions.push({
        label: 'View invoice',
        action: () => navigate(`/subscriptions/invoice/${subscription.id}`),
      })
    }
    return (
      <ActionMenu actions={actions} />
    )
  }

  const subscriptions = subscriptionQuery.getResponse()?.data?.items || []
  const loading = subscriptionQuery.getResponse()?.loading

  return (
    <div className="subscription-list">
      <Panel header={<h2>Car park subscriptions</h2>}>
        <p className="intro">
          Manage your subscriptions to suit your car parking needs.
          Your added
          {' '}
          <Link to="/vehicles">Vehicles</Link>
          {' '}
          will have access to any active subscriptions.
        </p>
        <div className="table">
          <Table
            wordWrap="break-word"
            data={subscriptions}
            loading={loading}
            autoHeight
          >
            <Table.Column flexGrow={1}>
              <Table.HeaderCell>Car park</Table.HeaderCell>
              <TableData dataKey="carpark" content={renderCarpark} />
            </Table.Column>
            <Table.Column flexGrow={0.6}>
              <Table.HeaderCell>Parking bays</Table.HeaderCell>
              <TableData dataKey="vehicles" content={renderVehicles} />
            </Table.Column>
            <Table.Column flexGrow={0.6}>
              <Table.HeaderCell>Total price</Table.HeaderCell>
              <TableData dataKey="price" content={calculateTotalPrice} format="money" />
            </Table.Column>
            <Table.Column flexGrow={1}>
              <Table.HeaderCell>Status</Table.HeaderCell>
              <TableData dataKey="status" content={renderStatus} />
            </Table.Column>
            <Table.Column flexGrow={1}>
              <Table.HeaderCell>Start date</Table.HeaderCell>
              <TableData dataKey="startDate" format="date" />
            </Table.Column>
            <Table.Column flexGrow={1}>
              <Table.HeaderCell>End date</Table.HeaderCell>
              <TableData dataKey="endDate" format="date" />
            </Table.Column>
            <Table.Column flexGrow={0.5}>
              <Table.HeaderCell>Actions</Table.HeaderCell>
              <TableData dataKey="actions" content={renderActions} />
            </Table.Column>
          </Table>
        </div>
        <Button
          onClick={handleNewSubscriptionCb}
          loading={userPaymentRes.loading}
        >
          Add Subscription
        </Button>
        <Modal hook={createModal}>
          <MissingPaymentMethodModal onClose={createModal.hide} />
        </Modal>
        <Modal hook={cancelModal}>
          <SubscriptionCancellationModal
            onClose={cancelModal.hide}
            onConfirm={sendCancellation}
            subscription={selectedSubscription}
            endDate={getEndDateQuery.getResponse().data}
            saving={cancelSubscriptionQuery.getResponse().loading}
          />
        </Modal>
        <Modal hook={modifyModal}>
          <SubscriptionModifyModal
            onClose={modifyModal.hide}
            onConfirm={sendModification}
            subscription={selectedSubscription}
            saving={cancelSubscriptionQuery.getResponse().loading}
          />
        </Modal>
      </Panel>
    </div>
  )
}

export {
  CustomerSubscriptionList
}
